// export const BaseIP = 'http://192.168.29.102:8082/techno-muhurt/';  //local
export const BaseIP = 'http://157.66.191.17:9080/techno-muhurt/';  //local 2nd
// export const BaseIP = 'http://103.104.74.225:9080/techno-muhurt/';    //online



// export const getReportDataApi= "http://192.168.29.102:8082/" // get Report Data Api  (local)
export const getReportDataApi= "http://157.66.191.17:9080/" // get Report Data Api  (local 2nd)
// export const getReportDataApi= "http://103.104.74.225:9080/" // get Report Data Api  (online)

export const adminLogin= BaseIP+ "muhurt/user/signIn"







// export const BaseURL = "http://192.168.29.102:8082";  //local
export const BaseURL = "http://157.66.191.17:9080"; //local 2nd

export const imagePath = BaseURL + "/muhurt/images/";
