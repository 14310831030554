import React, { useState, useEffect } from "react";
import TitleLabel from "../../CommonComponents/TitleLabel";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import {
  deleteMuhurtMaster,
  getMuhurtMasterList,
  saveMuhurtMaster,
  updateMuhurtMaster,
} from "../Services/MasterApi";
import { Card, Form } from "react-bootstrap";
import { BaseIP, imagePath } from "../Services/CommonApi";

const useStyles = makeStyles({
  caption: {
    padding: 0,
    fontWeight: 500,
    fontSize: "12px",
  },
  toolbar: {
    padding: "0px",
    margin: "0px",
  },
  menuItem: {
    fontSize: "12px",
    padding: "4px",
  },
  displayedRows: {
    fontSize: "12px",
    padding: "0px",
    margin: "-15px",
  },

  selectLabel: {
    fontSize: "12px",
    padding: "0px",
    margin: "0px -4px 0px 6px ",
  },
  spacer: {
    padding: "0px",
    margin: "0px",
    flex: "none",
  },
  input: {
    fontSize: "5px",
    padding: "0px",
    margin: "0px",
  },
  root: {
    padding: "0px",
    margin: "0px",
  },
});

const MuhartaMaster = () => {
  // const titleName = "नवीन मुहूर्त प्रकार";
  const titleName = "MUHURT MASTER";

  const [data, setData] = useState([]);
  const [Id, setId] = useState();
  const classes = useStyles();
  const authUser = useSelector((state) => state.user.value);
  const [isAscending, setAscending] = useState(false);
  const [sortedBy, setSortedBy] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(true);

  const data1 = sessionStorage.getItem("token");

  const onPageChange = (event, nextPage) => {
    setPage(nextPage);
  };
  const onRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value));
    setPage(0);
  };
  const [Image, setImage] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    if (data1) {
      setToken(data1);
    } else {
      setToken(null);
    }
  }, []);

  const TableHeading = [
    { label: "अनु. क्र.", key: "id" },
    { label: `मुहूर्त प्रकार`, key: "nameMr" },
    { label: `फॉर्म प्रकार`, key: "nameEn" },
    { label: "Edit", key: "edit", value: "edit", isAction: true },
    { label: "Delete", key: "del", value: "delete", isAction: true },
  ];

  const [name, setName] = useState("");
  const [formType, setFormType] = useState("");

  const cancleEntry = () => {
    setName("");
    setFormType("");
    setImage("");
  };
  const cancleEntry1 = () => {
    cancleEntry();
    setShowSaveButton(true);
    setShowUpdateButton(false);
  };

  //=========get data code============
  const getMuhurtData = () => {
    axios
      .get(getMuhurtMasterList, {
        headers: {
          token: data1,
        },
      })
      .then((res) => {
        if (res.data.json !== null) {

          const api = res.data
          console.log("Data", api)
          api.map((api, index) => {
            setImage(api.image)
           
          })
          setupdateimage(true)
          setUpdate(true)
          console.log("res get ...",res.data);
          setData(res.data);
          setFilteredData(res.data);
        }
        
      
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getMuhurtData();
  }, []);

  const [filteredData, setFilteredData] = useState(data);
  const onSort = (key, isAction) => {
    if (!isAction) {
      setSortedBy(key);
      if (isAscending)
        setFilteredData([...data.sort((a, b) => (a[key] < b[key] ? 1 : -1))]);
      else
        setFilteredData([...data.sort((a, b) => (a[key] > b[key] ? 1 : -1))]);
      setAscending(!isAscending);
    }
  };
  useEffect(() => {
    setFilteredData(
      data.filter(
        (item) =>
          item.muhurtName
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          // item.type.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.id.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm]);

  //============save data code===========

  const saveFun = (e) => {
    e.preventDefault();
    if (!name || !formType || !Image) {
      toast.error("Please fill in all fields and upload an image", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }
  
    const formData = new FormData();
    formData.append("image", Image);
    formData.append("muhurtName", name);
    formData.append("type", formType);

    console.log(formData);

    axios
      .post(saveMuhurtMaster, formData, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        if (response.data == "SAVED") {
          toast.success("Muhurt Saved Successfully!!!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          cancleEntry();
          getMuhurtData();
        } else if (response.data == "ALREADYEXISTS") {
          toast.warn("Muhurt Already Exist!!!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          toast.error("Please Select All Fields !!!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Catch Block:" + err.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
  };

  //==========update data code=====================

  const setEditDataFun = (item) => {
    setId(item.id);
    setName(item.muhurtName);
    setImage(item.imgName);
    setFormType(item.type);

    setShowUpdateButton(true);
    setShowSaveButton(false);
  };

  const [update, setUpdate] = useState(false)
  const [updateimage, setupdateimage] = useState(false)

  const handleUpdate = () => {
  

    const formData = new FormData();
    formData.append("id", Id);
    formData.append("image", Image);
    formData.append("muhurtName", name);
    formData.append("type", formType);

    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }


    axios
      .put(updateMuhurtMaster, formData, {
        headers: {
          token: token,
        },
      })
      .then((res) => {
        if (res.data === "UPDATED") {
          getMuhurtData();
          cancleEntry();
          setShowUpdateButton(false);
          setShowSaveButton(true);
          setupdateimage(true);

          toast.success("Muhurt Updated Successfully!!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else if (res.data === "ALREADYEXISTS") {
          toast.warn("Muhurt Already Exist!!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }  
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //=================delete data code==================

  //==CSS for delete modal=======
  const confirmationModal = {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    background: " rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 999,
  };

  const modalContent = {
    background: " #fff",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
  };

  const modalButtons = {
    display: "flex",
    marginTop: "20px",
    justifyContent: "center",
  };

  const Buttons1 = {
    color: "white",
    backgroundColor: "red",
    border: "1px solid red",
    borderRadius: "5px",
    padding: "5px 15px",
    margin: "0px 10px",
  };

  const Buttons2 = {
    color: "black",
    backgroundColor: "#F1C40F",
    border: "1px solid #F1C40F",
    borderRadius: "5px",
    padding: "5px 15px",
    margin: "0px 10px",
  };

  //=======================
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const handleDelete = (item) => {
    setItemToDelete(item);

    setShowConfirmationModal(true);
  };

  const confirmDelete = () => {
    axios
      .delete(`${deleteMuhurtMaster}?id=${itemToDelete.id}`, {
        headers: {
          token: token,
        },
      })
      .then((res) => {
        if (res.data === "DELETED") {
          getMuhurtData();
          toast.success("Muhurt deleted successfully!!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          const updatedData = data.filter(
            (item) => item.id !== itemToDelete.id
          );
          setData(updatedData);
        } else if (res.data === "ERROR") {
          toast.error("An error occurred during delete!!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else if (res.data === "NOTFOUND") {
          toast.warn("Muhurt not found for delete!!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }

        setShowConfirmationModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setImgfun=(e)=>{
    setupdateimage(false)
    setImage(e.target.files[0])
  }

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  //=======================================

  return (
    <>
      <div className="container">
        <TitleLabel titleName={titleName} />
        <div
          style={{
            backgroundColor: "#fff",
            padding: "16px",
            boxShadow: "0px 2px 15px #CECECE",
            marginTop: "16px",
            marginBottom: "20px",
          }}
        >
          <form>
            <div className="form-group col-sm-6 my-2">
              <div className="row">
                <label
                  htmlFor="inputName"
                  className="col-sm-3 col-form-label"
                  style={{ fontWeight: "600", fontSize: "14px" }}
                >
                  मुहूर्त प्रकार :{" "}
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="form-control form-control-sm"
                    id="inputName"
                    placeholder="मुहूर्त प्रकार"
                  />
                </div>
              </div>
            </div>
            <div className="form-group col-sm-6 my-2">
              <div className="row">
                <label
                  htmlFor="inputPassword3"
                  className="col-sm-3 col-form-label"
                  style={{ fontWeight: "600", fontSize: "14px" }}
                >
                  फॉर्म प्रकार :{" "}
                </label>
                <div className="col-sm-7">
                  <select
                    value={formType}
                    onChange={(e) => setFormType(e.target.value)}
                    className="form-select form-select-sm"
                    aria-label=".form-select-sm example"
                  >
                    <option value="" disabled>
                      फॉर्म प्रकार
                    </option>
                    <option value="1">फक्त मुहूर्त</option>
                    <option value="2">मुहूर्त पासून पर्यंत</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="form-group col-sm-6 my-2">
              <div className="row">
              <label
                  htmlFor="inputPassword3"
                  className="col-sm-3 col-form-label"
                  style={{ fontWeight: "600", fontSize: "14px" }}
                >
                  फोटो :{" "}
                </label>
                <div className="col-sm-7">
                <Form.Control
                  type="file"
                  size="sm"
                  accept=".jpg,.jpeg,.png"
                  onChange={(e) => setImgfun(e)}
                  required
                  name="Image"
                />
                </div>
              </div>
            </div>



            <div className="form-group col-sm-6 my-2">
            <div className="row">  
            <label
                  htmlFor="inputPassword3"
                  className="col-sm-3 col-form-label"
                  style={{ fontWeight: "600", fontSize: "14px" }}
                >
                </label>
            <div className="col-sm-7">
      <Card className='text-center' style={{ width: '13rem' , border:"0px",height:"12rem" }}>
        <Card.Img variant="top" loading="lazy" src={updateimage ? `${imagePath}${Image}`: (Image ? URL.createObjectURL(Image) : '') }  style={{ height: '8rem'}} />
        <Card.Body>
          <Card.Text className='text-center'>
          <b>Uploaded Image </b>
          </Card.Text>
        </Card.Body>
      </Card>
      </div>
      </div>
            </div>












            <div className="form-group col-sm-6 my-2">
              <div className="row">
                <div className="col-sm-3"></div>
                <div className="col-sm-6">
                  {showSaveButton && (
                    <>
                      <button
                        type="submit"
                        className="btn btn-sm saveBtn text-light"
                        onClick={saveFun}
                      >
                        Save
                      </button>

                      <button
                        type="submit"
                        className="btn btn-sm mx-2 cancleBtn text-light"
                        onClick={cancleEntry}
                      >
                        Cancel
                      </button>
                    </>
                  )}

                  {showUpdateButton && (
                    <>
                      <button
                        className="btn btn-sm mx-2 text-light"
                        type="submit"
                        style={{
                          color: "black",
                          backgroundColor: "orangered",
                          border: "1px solid orangered",
                        }}
                        onClick={() => handleUpdate()}
                      >
                        Update
                      </button>

                      <button
                        type="submit"
                        className="btn btn-sm mx-2 cancleBtn text-light"
                        onClick={cancleEntry1}
                      >
                        Cancel
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </form>
          <hr />
          <div className="row">
            <div>
              <input
                className="form-control form-control-sm"
                type="text"
                style={{
                  width: "250px",
                  display: "block",
                  float: "right",
                  marginBottom: "0px",
                  border: "1px solid #C2C1C1",
                  fontSize: "12px",
                }}
                placeholder="Search Here"
                value={searchTerm}
                onChange={(event) => {
                  setSearchTerm(event.target.value);
                }}
              />
            </div>
          </div>
          <div className="table-responsive mt-2">
            <table className="table table-bordered">
              <thead className="table-Default">
                <tr
                  style={{
                    backgroundColor: "#EDC3FF",
                    backgroundImage:
                      "linear-gradient(200deg, #EDC3FF 10%, #aacaef 100%)",
                  }}
                >
                  {TableHeading.map((item, ind) => {
                    return (
                      <th
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          borderBottom: "2px solid #D8EDF7",
                        }}
                        onClick={() => onSort(item.key, item.isAction)}
                        key={ind}
                        scope="col"
                      >
                        {`${item.label} ${
                          sortedBy === item.key ? (isAscending ? "↑" : "↓") : ""
                        }`}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody style={{ borderBottom: "2px solid #D8EDF7" }}>
                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <td style={{ fontSize: "15px", fontWeight: "500" }}>
                          {item.id}
                        </td>
                        <td style={{ fontSize: "15px", fontWeight: "500" }}>
                          {item.muhurtName}
                        </td>
                        <td style={{ fontSize: "15px", fontWeight: "500" }}>
                          {item.type == 1
                            ? "फक्त मुहूर्त"
                            : "मुहूर्त पासून पर्यंत"}
                        </td>
                        <td style={{ width: "80px" }}>
                          <span className="mx-1 p-1" data-bs-toggle="tooltip">
                            <ModeEditIcon
                              fontSize="small"
                              style={{ cursor: "pointer", color: "#4A78FF" }}
                              onClick={() => setEditDataFun(item)}
                            />
                          </span>
                        </td>
                        <td style={{ width: "80px" }}>
                          <span
                            className="mx-1 p-1"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <DeleteIcon
                              fontSize="small"
                              style={{ cursor: "pointer", color: "#CD2F44" }}
                              onClick={() => handleDelete(item)}
                            />
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>

              {showConfirmationModal && (
                <div style={confirmationModal}>
                  <div style={modalContent}>
                    <p>Are you sure you want to delete this Muhurt?</p>
                    <div style={modalButtons}>
                      <button onClick={confirmDelete} style={Buttons1}>
                        Delete
                      </button>
                      <button onClick={closeConfirmationModal} style={Buttons2}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}

              <tfoot>
                <tr style={{ padding: "4px" }}>
                  <TablePagination
                    style={{ padding: 0, borderBottom: "0px" }}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    labelRowsPerPage=" Rows : "
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onRowsPerPageChange={onRowsPerPageChange}
                    onPageChange={onPageChange}
                    classes={{
                      root: classes.root,
                      input: classes.input,
                      spacer: classes.spacer,
                      selectLabel: classes.selectLabel,
                      toolbar: classes.toolbar,
                      caption: classes.caption,
                      menuItem: classes.menuItem,
                      displayedRows: classes.displayedRows,
                    }}
                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                    nextIconButtonProps={{ "aria-label": "Next Page" }}
                  />
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <ToastContainer theme="colored" />
    </>
  );
};

export default MuhartaMaster;
